import { v4 as uuid } from 'uuid';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../Context/AppContextProvide';
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Slider, TextField, Tooltip, Typography } from '@material-ui/core';
import { AttachFile, Delete, ExpandMore } from '@material-ui/icons';
import { autoProcess, clearPromptSession, deletePromptMessage, deletePromptMessages, getAIModels, getDefaultAIModel, getPromptSessionHistory, getPromptSessions, getSystemInstructions, sendPromptMessage, updatePromptSession } from '../../Actions';

import '../styles/Prompt.css';
import Message from './Message';

const STARTING_MESSAGE = `Objective: Convert the provided price list data to a CSV Output (no code, data only) that adheres to the specified System Instructions.

System Instructions: Refer to the separate "System Instructions Document" for detailed data mappings, rules, valid value lists ('material_class', 'uom_type'), and CSV header definitions. Pay particular attention to:

* Interchangeable Words: Apply synonym replacements as defined (product/style, color/option, etc.).
* CSV Headers: CSV must use the exact headers and order specified.
* Header Field Definitions & Rules: Adhere to all field-specific instructions, including blank field requirements and data population logic.
* Valid Values Lists: Use provided lists for 'material_class' and 'uom_type' (case-insensitive matching). Create concise 3-character codes for 'material_class' if no direct match is found.

Task:

1. Data Extraction & Mapping: Process the input price list data. Extract relevant information and map it to the CSV headers according to the System Instructions.
    * Distributor & Manufacturer: Identify and populate these fields. Use the same name if they are identical.
    * Style vs. SKU: Maintain a clear distinction between style-level data ('style_number', 'style_name') and SKU-level data ('sku', 'color') as defined in the System Instructions.
    * Material Class & UOM Type Validation: Validate extracted values against the provided valid value lists (case-insensitive). Generate 3-character 'material_class' codes as needed.
    * Currency Default: Default 'currency_cd' to "USD" if not explicitly provided in the input data.
    * Effective End Date Logic: For discontinued items, use the provided end date or today's date if no end date is available.
    * Blank Field Handling: Leave 'display_name', 'material_sub_class', and 'retail_price_override' fields blank. Leave 'basis_cd', 'thickness', 'wear_layer', 'ounce_weight', 'private_style_number', and 'private_style_name' blank if the information is not found in the input price list.

2. CSV Output: Generate a CSV Output.
    * Headers: Use the exact CSV headers and order specified in the System Instructions.
    * Value Count: Make sure each data row has exactly the same number of columns as the header row.
    * Value Enclosure: Enclose all CSV values (including numeric and blank values) in double quotes ('"') unless the value is already enclosed in double quotes. This ensures proper CSV formatting and avoids potential parsing issues.
    * Double Quote Escaping: If any value contains double quotes, escape them by replacing them with two double quotes (e.g., "4" x 36" x 3/4"" outputs as "4"" x 36"" x 3/4""").`;

/**
 * TODO: Think about creating a streamlined prompt that just takes you through the conversion steps, but lets you step in when need to change things around
 */

const AIPrompt = () => {
  const history = useHistory();
  const { user: userData } = useContext(AppContext);
  const [defaultModelConfig, setDefaultModelConfig] = useState();
  const [defaultSI, setDefaultSI] = useState('');
  const [openConfig, setOpenConfig] = useState();
  const [sessions, setSessions] = useState([]);
  const [models, setModels] = useState([]);

  const [selectedSessionId, setSelectedSessionId] = useState('');
  const [sessionName, setSessionName] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [modelConfig, setModelConfig] = useState('');
  const [systemInstructions, setSystemInstructions] = useState('');
  const [message, setMessage] = useState('');
  const [attachedFile, setAttachedFile] = useState();
  const [sessionHistory, setSessionHistory] = useState([]);
  const [newSessionId, setNewSessionId] = useState('');
  
  const [tokenCounter, setTokenCounter] = useState(0);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [autoLoopStartId, setAutoLoopStartId] = useState(-1);
  const [isLoopDialogOpen, setIsLoopDialogOpen] = useState(false);
  const [isLoopSuccessDialogOpen, setIsLoopSuccessDialogOpen] = useState(false);
  const [isLoopErrorDialogOpen, setIsLoopErrorDialogOpen] = useState(false);
  const [loopError, setLoopError] = useState();
  const [fileIdsToConcatenate, setFileIdsToConcatenate] = useState([]);
  
  const fileInputRef = useRef(null);
  const promptWindowRef = useRef(null);
  const pollHistoryRef = useRef(null);

  async function getSIs() {
    await getSystemInstructions().then(response => {
      setDefaultSI(response.data.data);
      setSystemInstructions(response.data.data);
    }).catch(err => {
      setDefaultSI('');
      setSystemInstructions('');
    });
  }

  async function getSessions() {
    await getPromptSessions().then(response => {
      setSessions([ ...response.data.sessions ]);
    }).catch(err => {
      setSessions([]);
    });
  }

  async function getDefaultModel(models) {
    await getDefaultAIModel().then(response => {
      const defaultModelConfig = models.find(m => m.name === response.data.model);

      setModelConfig(defaultModelConfig);
      setDefaultModelConfig(defaultModelConfig);
      setSelectedModel(defaultModelConfig?.name ?? '');
    }).catch(err => {
      setDefaultModelConfig(undefined);
      setModelConfig(undefined);
      setSelectedModel('');
    });
  }

  async function getSessionHistory (sessionId) {
    await getPromptSessionHistory(sessionId).then(response => {
      if (!response.data.history?.length) {
        setSessionHistory([]);
        return;
      }

      setSessionHistory([ ...response.data.history ]);
      setOpenConfig(undefined);
      setTimeout(() => promptWindowRef.current?.scrollIntoView({ behavior: "auto", block: "end" }), 300);
    }).catch(err => {
      setSessionHistory([]);
    })
  }

  useEffect(() => {
    async function getModels() {
      await getAIModels().then(response => {
        setModels(response.data.models);
        if (!selectedModel) getDefaultModel(response.data.models);
      }).catch(err => {
        setModels([]);
      });
    }

    if (!newSessionId) {
      setNewSessionId(uuid());
      setMessage(STARTING_MESSAGE);
    }

    getSIs();
    getModels();
    getSessions();
  }, [])

  useEffect(() => {
    const sessionId = selectedSessionId ? selectedSessionId : newSessionId;

    if (!isLoopDialogOpen) {
      if (pollHistoryRef?.current) clearInterval(pollHistoryRef.current);
      if (selectedSessionId) getSessionHistory(sessionId);
      return;
    }

    pollHistoryRef.current = setInterval(() => {
      getSessionHistory(sessionId);
    }, 1000 * 10);

    return () => {
      if (pollHistoryRef?.current) clearInterval(pollHistoryRef.current);
    }
  }, [isLoopDialogOpen])

  useEffect(() => {
    if (!sessionHistory?.length) {
      setAutoLoopStartId(undefined);
      setFileIdsToConcatenate([]);
      return;
    }

    const loopStartId = sessionHistory.find((m, i, history) => m.role === 'model' && /^(Did you finish converting the entire file\?)/.test(history[i + 1]?.message))?.promptHistoryId;

    if (loopStartId) {
      const fileIds = sessionHistory.filter(m => m.role === 'model' && !/(^yes)|(^no)/.test(m.message) && m.promptHistoryId >= loopStartId)?.map(m => m.messageId);
      setFileIdsToConcatenate(fileIds);
    }
    
    setAutoLoopStartId(loopStartId);
  }, [sessionHistory])

  const submitPrompt = async () => {
    const messageId = uuid();
    const fileId = uuid();

    const newSessionHistory = [ ...sessionHistory ];

    if (message) {
      const promptTextRecord = {
        role: 'user',
        messageId: messageId,      
        message: message,
        type: 'text',
        error: '',
        tokenCount: undefined
      };

      newSessionHistory.push(promptTextRecord);
    }

    if (attachedFile) {
      const promptFileRecord = {
        role: 'user',
        messageId: fileId,      
        message: attachedFile.name,
        type: 'file',
        error: '',
        tokenCount: undefined
      };

      newSessionHistory.push(promptFileRecord);
    }
    
    setSessionHistory([ ...newSessionHistory ]);

    const formData = new FormData();
    formData.append('messageId', messageId);
    formData.append('sessionId', selectedSessionId ? selectedSessionId : newSessionId);
    formData.append('systemInstructions', systemInstructions);
    formData.append('message', message);

    if (sessionName) {
      formData.append('sessionName', sessionName);
    }

    if (modelConfig) {
      formData.append('modelConfig', modelConfig ? JSON.stringify(modelConfig) : null);
    } else {
      const model = models.find(m => m.name === selectedModel);
      formData.append('modelConfig', model ? JSON.stringify(model) : null);
    }

    if (attachedFile) {
      formData.append('fileId', fileId);
      formData.append('attached_file', attachedFile);
      
    } else {
      formData.append('fileId', undefined);
      formData.append('attached_file', undefined);
    } 

    await sendPromptMessage(formData).then(async response => {

      if (message) {
        const index = newSessionHistory.findIndex(h => h.messageId === messageId);

        newSessionHistory[index] = {
          ...newSessionHistory[index],
          tokenCount: response.data.messageTokenCount
        };
      }

      if (attachedFile) {
        const index = newSessionHistory.findIndex(h => h.messageId === fileId);

        newSessionHistory[index] = {
          ...newSessionHistory[index],
          tokenCount: response.data.fileTokenCount
        };
      }
      
      const responseRecord = {
        role: 'model',
        messageId: response.data.responseId,      
        message: response.data.text,
        type: 'text',
        error: '',
        tokenCount: response.data.responseTokenCount
      };

      const totalPromptTokenCount = (response.data.messageTokenCount || 0) + (response.data.fileTokenCount || 0) + (response.data.metadata.thoughtsTokenCount || 0) + (response.data.responseTokenCount || 0);

      setTokenCounter(tokenCounter + totalPromptTokenCount);
      setSessionHistory([ ...newSessionHistory, responseRecord ]);
      setMessage('');
      setAttachedFile(undefined);

      if (!selectedSessionId) await getSessions();

      setSelectedSessionId(newSessionId);
      setNewSessionId(uuid());
    }).catch((err) => {
      if (message) {
        const index = newSessionHistory.findIndex(h => h.messageId === messageId);

        newSessionHistory[index] = {
          ...newSessionHistory[index],
          error: JSON.stringify(err)
        };
      }

      if (attachedFile) {
        const index = newSessionHistory.findIndex(h => h.messageId === fileId);

        newSessionHistory[index] = {
          ...newSessionHistory[index],
          error: JSON.stringify(err)
        };
      }

      setSessionHistory([ ...newSessionHistory ]);
    });

    setIsAwaitingResponse(false);
  }

  const downloadFile = async (data, mimeType) => {
    let fileType = 'text/plain';

    if (mimeType === 'json') {
      fileType = 'application/json';
    } else if (mimeType === 'csv') {
      fileType = 'text/csv';
    }

    const filename = sessionHistory?.find(m => ['file', 'csv', 'xlsx', 'pdf'].includes(m.type))?.message?.replace(/\.[A-Za-z]{2,}$/, '');
    const file = new Blob([data], {type: fileType});
    const element = document.createElement("a");

    element.href = URL.createObjectURL(file);
    if (filename) {
      element.download = filename + ` (CBP).${mimeType}`;
    } else {
      element.download = "PromptDownload-" + new Date().getTime() + `.${mimeType}`;
    }
    
    document.body.appendChild(element); 

    element.click();

    document.body.removeChild(element);
    URL.revokeObjectURL(file);
  }

  const generateConcatenatedFile = async (fileIdsToConcatenate, sessionHistory) => {
    const fileResponses = sessionHistory.filter(r => fileIdsToConcatenate.includes(r.messageId) && !!r.message).map((r, index) => {
      const csvParsingRegex = /(?<=,),(?!\s)|(?<!\s),(?=,)|(?<="),(?!\s)|(?<!\s),(?=")|(?<=[A-z0-9]),(?=\n)|(?<=[A-z0-9]),(?!\s)|(?<!\s),(?=[A-z0-9])/g;
      const parsedMessage = r.message.replace(/(```[a-z]+\n)|(```)/g, '').replace(/\n$/, '').replaceAll(/"{4,}/g, '"""');
      const rows = parsedMessage.split('\n');
      const headers = rows[0].split(csvParsingRegex);
      const lastRecord = rows[rows.length - 1].split(csvParsingRegex);
      let records; 

      if (lastRecord.length < headers.length) {
        records = index === 0 ? rows.slice(0, -1) : rows.slice(1, -1);
      } else {
        records = index === 0 ? rows : rows.slice(1);
      }

      return records.join('\n');
    });

    const fileData = fileResponses.join('\n');
    await downloadFile(fileData, 'csv');
  }

  const autoProcessFile = async () => {
    setIsLoopDialogOpen(true);
    const sessionId = selectedSessionId ? selectedSessionId : newSessionId;
    const modelConfig = models?.find(m => m.name === selectedModel);

    let isSuccess = false;
    let error;

    await autoProcess(sessionId, modelConfig ? JSON.stringify(modelConfig) : null).then(response => {
      isSuccess = true;
    }).catch(err => {
      isSuccess = false;
      error = err;
    });

    await getSessionHistory(sessionId);
    await getSessions();
    setIsLoopDialogOpen(false);

    if (isSuccess) {
      setIsLoopSuccessDialogOpen(true);
    } else {
      setLoopError(error);
      setIsLoopErrorDialogOpen(true);
    }
  }

  const clearAutoFileProcessing = async (promptHistoryId, history) => {
    if (!promptHistoryId || !history.length) return;

    const sessionId = selectedSessionId ? selectedSessionId : newSessionId;
    const messageIdsToRemove = history.filter(m => m.promptHistoryId > promptHistoryId)?.map(m => m.messageId);

    setFileIdsToConcatenate([]);
    await deletePromptMessages(sessionId, messageIdsToRemove);
    await getSessionHistory(sessionId);
    await getSessions();
  }

  const updateSessionConfig = async (sessionId, sessionName, modelConfig, systemInstructions) => {
    await updatePromptSession(sessionId, sessionName, modelConfig, systemInstructions).then(async (response) => {
      await getSessions();
    }).catch(err => {

    })
  }

  const clearChat = async (sessionId) => {
    if (sessionId) {
      await clearPromptSession(sessionId);
    }

    await getSessions();
    setSelectedSessionId('');
    setSelectedModel(defaultModelConfig?.name ?? '');
    setModelConfig(defaultModelConfig);
    setSystemInstructions(defaultSI);
    setSessionHistory([]);
    setSessionName('');
    setMessage(STARTING_MESSAGE);
    setAttachedFile(undefined);
    fileInputRef.current.value = [];
    setTokenCounter(0);
    setIsAwaitingResponse(false);
    setFileIdsToConcatenate([]);
  }

  const changeSession = async (sessionId) => {
    setSelectedSessionId(sessionId);

    if (!sessionId) {
      await clearChat();
      return;
    }

    const session = sessions?.find(s => s.session_uuid === sessionId);

    if (session) {
      setSystemInstructions(session?.system_instructions || '');
      setSelectedModel(session?.model?.name ?? defaultModelConfig?.name ?? '');
      setModelConfig(session?.model ?? defaultModelConfig);
      setSessionName(session?.name || '');
      setTokenCounter(session.token_count ?? 0);
      setMessage('');
      setAttachedFile(undefined);
      setSessionHistory([]);
      fileInputRef.current.value = [];
      setIsAwaitingResponse(false);
      await getSessionHistory(sessionId);
    }
  }

  const deleteMessage = async (sessionId, messageId) => {
    await deletePromptMessage(sessionId, messageId);
    await getSessionHistory(sessionId);
  }

  const attachFileToPrompt = async (e) => {
    const file = e.target.files[0];
    setAttachedFile(file);
    fileInputRef.current.value = [];
  }

  const resetToModelMax = async () => {
    const modelMax = models.find(m => m.name === modelConfig?.name)?.outputTokenLimit;

    if (!modelMax) return;
    
    setModelConfig({ ...modelConfig, outputTokenLimit: modelMax });
  }

  if (!userData) return <div>{history.push('/signin')}</div>;

  const messages = sessionHistory?.map((m, index) => {
    const isAutoLoopStart = autoLoopStartId && m.promptHistoryId === autoLoopStartId;
    const isInAutoLoop = fileIdsToConcatenate.includes(m.messageId) && fileIdsToConcatenate.length > 1;
    const canAutoLoop = !autoLoopStartId && index === sessionHistory.length - 1;
    const canContinueAutoLoop = fileIdsToConcatenate.includes(m.messageId) && index === sessionHistory.length - 1;

    return (
      <Message 
        key={m.messageId} 
        message={m}
        isAutoLoopStart={isAutoLoopStart}
        isInAutoLoop={isInAutoLoop}
        canAutoLoop={canAutoLoop}
        canContinueAutoLoop={canContinueAutoLoop}
        sessionId={selectedSessionId || newSessionId}
        downloadFile={(data, mimeType) => downloadFile(data, mimeType)}
        deleteMessage={(messageId) => deleteMessage(selectedSessionId || newSessionId, messageId)}
        startAutoFileProcessing={() => autoProcessFile()}
        clearAutoFileProcessing={() => clearAutoFileProcessing(autoLoopStartId, sessionHistory)}
        generateConcatenatedFile={() => generateConcatenatedFile(fileIdsToConcatenate, sessionHistory)}
      />
    )
  });

  return (
    <div style={{padding: "30px 20px"}}>
      <Paper style={{padding: "20px"}}>
        <div style={{height: "70px", position: "relative"}}>
          <div style={{position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px", zIndex: "10"}}>
            <Accordion elevation={4} expanded={openConfig === 'prompt'}>
              <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="sample-content"
                id="sample-header"
                style={{backgroundColor: "#f5f5f5"}}
                onClick={() => setOpenConfig(openConfig === 'prompt' ? undefined : 'prompt')}
              >
                <Typography variant="h5">Prompt Config & Details</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: "block", padding: "20px 15px", backgroundColor: "#FFFFFF"}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth style={{margin: '0px 10px 30px 0px', maxWidth: "400px", display: "inline-block", verticalAlign: "middle"}}>
                      <InputLabel shrink id="session-selection-label">Saved Sessions</InputLabel>
                      <Select
                        labelId="session-selection-label"
                        id="session-selection"
                        value={selectedSessionId}
                        fullWidth
                        variant="filled"
                        onChange={(e) => changeSession(e.target.value)}
                        displayEmpty
                      >
                        <MenuItem value={''}>
                          New Session
                        </MenuItem>
                        {sessions?.map((s) => {
                          return (
                            <MenuItem key={s.session_uuid} value={s.session_uuid}>
                              {s.name || s.session_uuid}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <Typography variant="subtitle2">Session ID: {selectedSessionId || newSessionId}</Typography>
                    <Typography variant="subtitle2">Token Count: {tokenCounter}</Typography>
                    <Typography variant="subtitle2">Message Count: {sessionHistory.length}</Typography>
                    <TextField 
                      value={sessionName} 
                      fullWidth
                      variant='filled'
                      placeholder='Session Name'
                      onChange={(e) => setSessionName(e.target.value)}
                      style={{maxWidth: "400px", margin: "15px 0px 0px"}}
                    /> 
                    <FormControl fullWidth style={{margin: '20px 0px 0px', maxWidth: "400px", display: "block", verticalAlign: "middle"}}>
                      <InputLabel shrink id="prompt-model-label">Prompt Model</InputLabel>
                      <Select
                        labelId="prompt-model-label"
                        id="prompt-model"
                        value={selectedModel}
                        fullWidth
                        variant="filled"
                        onChange={(e) => {
                          setSelectedModel(e.target.value);

                          const selectedModelConfig = models?.find(m => m.name === e.target.value);
                          setModelConfig(selectedModelConfig);
                        }}
                      >
                        {models.map((m) => {
                          return (
                            <MenuItem key={m.name} value={m.name}>
                              <span>
                                <Typography><b>{m.name}</b></Typography>
                                <Typography variant="caption" style={{display: "block"}}>Token Limit: {m.inputTokenLimit}</Typography>
                                <Typography variant="caption" style={{display: "block"}}>Output Limit: {m.outputTokenLimit}</Typography>
                              </span>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    { selectedSessionId ? (
                        <Button 
                          variant="contained" 
                          style={{display: "block", margin: '20px 0px 0px', backgroundColor: "#4caf50", color: "#ffffff"}}
                          onClick={() => updateSessionConfig(selectedSessionId, sessionName, modelConfig, systemInstructions)}
                        >
                          Update Session Config
                        </Button>
                      ) : undefined
                    }
                  </Grid>
                  { selectedModel ? (
                      <Grid item xs={12} md={6}>
                        <pre style={{whiteSpace: "pre-wrap", margin: "0px", padding: "10px", backgroundColor: "#f5f5f5"}}>
                          <Typography style={{margin: '0px 0px 10px'}}>Model Config</Typography>
                          <code>
                            {JSON.stringify(modelConfig, null, 2)}
                          </code>
                        </pre> 
                        <Typography style={{margin: '10px 0px 0px'}}>Temperature (Randomness)</Typography>
                        <Slider 
                          value={modelConfig?.temperature}
                          aria-labelledby="temperature-slider"
                          valueLabelDisplay="auto"
                          marks={[ 
                            {value: 0, label: "0"}, 
                            {value: modelConfig?.maxTemperature / 2, label: modelConfig?.maxTemperature / 2},
                            {value: modelConfig?.maxTemperature, label: modelConfig?.maxTemperature}, 
                          ]}
                          min={0}
                          max={Number(modelConfig?.maxTemperature)}
                          step={0.05}
                          onChange={(e, value) => {
                            if (!value) return;

                            setModelConfig({ ...modelConfig, temperature: value })
                          }}
                        />
                        <Typography style={{margin: '10px 0px 0px'}}>TopP (Selection Probability)</Typography>
                        <Slider 
                          value={modelConfig?.topP}
                          aria-labelledby="topP-slider"
                          valueLabelDisplay="auto"
                          marks={[ 
                            {value: 0, label: 0}, 
                            {value: .5, label: .5},
                            {value: 1, label: 1}, 
                          ]}
                          min={0}
                          max={1}
                          step={0.05}
                          onChange={(e, value) => {
                            if (!value) return;

                            setModelConfig({ ...modelConfig, topP: value })
                          }}
                        />
                        <Typography style={{margin: '10px 0px 0px'}}>TopK (Number of Most Probable Tokens)</Typography>
                        <Slider 
                          value={modelConfig?.topK}
                          aria-labelledby="topP-slider"
                          valueLabelDisplay="auto"
                          marks={[ 
                            {value: 0, label: 0}, 
                            {value: 50, label: 50},
                            {value: 100, label: 100}, 
                          ]}
                          min={0}
                          max={100}
                          step={1}
                          onChange={(e, value) => {
                            if (!value) return;

                            setModelConfig({ ...modelConfig, topK: value })
                          }}
                        />
                        <Typography style={{margin: '10px 0px 0px'}}>Output Limit</Typography>
                        <TextField
                          value={modelConfig?.outputTokenLimit} 
                          variant='filled'
                          type="number"
                          style={{}}
                          onChange={(e) => {
                            if (!e.target.value) return;

                            setModelConfig({ ...modelConfig, outputTokenLimit: parseInt(e.target.value) });
                          }}
                        />
                        <Button 
                          variant="contained" 
                          size="medium"
                          style={{display: "inline-block", verticalAlign: "bottom", margin: "0px 0px 0px 20px"}}
                          onClick={() => resetToModelMax()}
                        >
                          Reset
                        </Button>
                      </Grid>
                    ) : undefined
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div style={{height: "70px", position: "relative"}}>
          <div style={{position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px", zIndex: "5"}}>
            <Accordion elevation={4} expanded={openConfig === 'si'}>
              <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="sample-content"
                id="sample-header"
                style={{backgroundColor: "#f5f5f5"}}
                onClick={() => setOpenConfig(openConfig === 'si' ? undefined : 'si')}
              >
                <Typography variant="h5">System Instructions</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: "block", padding: "20px 10px", backgroundColor: "#f5f5f5"}}>
                <TextField 
                  value={systemInstructions} 
                  multiline 
                  fullWidth
                  variant='outlined'
                  onChange={(e) => setSystemInstructions(e.target.value)}
                  style={{maxHeight: "400px", overflowY: "scroll", whiteSpace: "pre-wrap", wordBreak: "break"}}
                />
                { selectedSessionId ? (
                    <Button 
                      variant="contained" 
                      style={{display: "block", margin: '20px 0px 0px', backgroundColor: "#4caf50", color: "#ffffff"}}
                      onClick={() => updateSessionConfig(selectedSessionId, sessionName, modelConfig, systemInstructions)}
                    >
                      Update Session Config
                    </Button>
                  ) : undefined
                }
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div style={{height: "60vh", overflowY: "scroll", padding: "15px 0px", border: "1px solid #e5e5e5", borderRadius: "10px"}}>
          <Grid container style={{padding: "10px 15px 10px 20px"}}>
            {messages}
          </Grid>
          <div ref={promptWindowRef}></div>
        </div>
        <div style={{marginTop: "20px"}}>
          <Grid container alignItems='flex-end' style={{backgroundColor: "#f5f5f5", minHeight: "100px", padding: "15px", borderRadius: "10px"}}>
            <Grid item xs={12} md={8} lg={9}>
              <TextField 
                value={message} 
                multiline 
                fullWidth
                variant='outlined'
                onChange={(e) => setMessage(e.target.value)}
                style={{maxHeight: "200px", marginTop: "10px", overflowY: "scroll", whiteSpace: "pre-wrap", wordBreak: "break"}}
              />
              {attachedFile ? (
                  <div style={{display: "inline-block", borderRadius: "10px", padding: "5px 10px", margin: "10px 0px", backgroundColor: "#d6d6d6"}}>
                    <Typography variant="body1" style={{display: "inline-block", verticalAlign: "middle"}}>{attachedFile.name}</Typography>
                    <Tooltip title="Remove File" aria-label="Remove File">
                      <IconButton 
                        style={{margin: "0px 0px 0px 10px"}} 
                        component="span" 
                        onClick={() => {
                          setAttachedFile(undefined);
                          fileInputRef.current.value = [];
                        }}
                      >
                        <Delete fontSize='small'/>
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : undefined
              }
            </Grid>
            <Grid item xs={12} md={4} lg={3} style={{textAlign: "right"}}>
              <input ref={fileInputRef} accept="image/*,.pdf,.csv,.xls,.xlsx,.gsheet" style={{display: "none"}} onChange={(e) => attachFileToPrompt(e)} id="file-import-button" type='file'/>
              <label htmlFor="file-import-button">
                <Tooltip title="File Upload" aria-label="File Upload">
                  <IconButton style={{margin: "10px 0px 0px 0px", border: "1px solid", padding: "8px"}} component="span"><AttachFile fontSize='small'/></IconButton>
                </Tooltip>
              </label>
              <Button 
                variant="contained" 
                onClick={() => {
                  clearChat(selectedSessionId ? selectedSessionId : newSessionId);
                  setOpenConfig(undefined);
                }}
                style={{margin: "10px 0px 0px 20px"}}
              >
                Clear Chat
              </Button>
              <Button 
                variant="contained"
                onClick={() => {
                  setIsAwaitingResponse(true);
                  setOpenConfig(undefined);
                  submitPrompt();
                }}
                style={{backgroundColor: "#142e3e", color: "#28c4fc", margin: "10px 0px 0px 20px"}}
              >
                {isAwaitingResponse ? <CircularProgress size={23} style={{color: "#28c4fc"}}/> : "Send"}
              </Button>  
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Dialog open={isLoopDialogOpen}>
        <DialogContent style={{textAlign: "center", padding: "30px"}}>
          <CircularProgress />
          <Typography variant='h6' style={{marginTop: "10px"}}>Looping through the rest of the file, please wait...</Typography>
        </DialogContent>
      </Dialog>
      <Dialog open={isLoopSuccessDialogOpen}>
        <DialogContent style={{textAlign: "center", padding: "30px"}}>
          <Typography variant='h6'>Looping Succeeded!</Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="contained"
            style={{margin: "10px 0px 0px 20px"}}
            onClick={() => setIsLoopSuccessDialogOpen(false)}
          >
            Close
          </Button>
          <Button 
            variant="contained"
            style={{backgroundColor: "#142e3e", color: "#28c4fc", margin: "10px 0px 0px 20px"}}
            onClick={() => {
              setIsLoopSuccessDialogOpen(false);
              generateConcatenatedFile(fileIdsToConcatenate, sessionHistory);
            }}
          >
            Download CSV
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isLoopErrorDialogOpen} maxWidth="md">
        <DialogContent style={{textAlign: "center", padding: "20px 20px 0px"}}>
          <Typography variant='h6'>Looping Failed!</Typography>
          <pre style={{textAlign: "left", whiteSpace: "pre-wrap", wordBreak: "break-all", padding: "10px", backgroundColor: "#f5f5f5"}}>
            <code>
              {JSON.stringify(loopError, null, 2)}
            </code>
          </pre>
        </DialogContent>
        <DialogActions style={{padding: "0px 20px 20px"}}>
          <Button 
            variant="contained"
            style={{margin: "10px 0px 0px 20px"}}
            onClick={() => {
              setLoopError('');
              setIsLoopErrorDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AIPrompt;