import React, { useState } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Delete, DynamicFeed, FileCopy, GetApp, Info, Loop, Restore, Subject, TableChart, Update, Warning} from '@material-ui/icons';

import '../styles/Prompt.css';
import { Alert } from '@material-ui/lab';

const cleanValue = (value) => {
  let parsedValue = value;
  parsedValue = parsedValue.replaceAll(/(^"(?!"))|((?<!")"$)|(^""$)/g, '').replaceAll(/"{2}/g, '"');

  return parsedValue;
}

export default function Message(props) {
  const [isTableView, setIsTableView] = useState(true);
  const csvParsingRegex = /(?<=,),(?!\s)|(?<!\s),(?=,)|(?<="),(?!\s)|(?<!\s),(?=")|(?<=[A-z0-9]),(?=\n)|(?<=[A-z0-9]),(?!\s)|(?<!\s),(?=[A-z0-9])/g;
  let clipboardData = ``;
  let messageType = 'text';
  let textChunks = [];
  let recordCount = 0;

  if (/^```csv/.test(props.message.message)) {
    const parsedMessage = props.message.message.replace(/(```[a-z]+\n)|(```)/g, '').replace(/\n$/, '').replaceAll(/"{4,}/g, '"""');
    const rows = parsedMessage.split('\n');
    const headers = rows[0].split(csvParsingRegex);
    const records = rows.slice(1);

    messageType = 'csv';
    recordCount = records.length;
    clipboardData = `${parsedMessage}`;

    if (isTableView) {
      textChunks.push(
        <TableContainer key={props.message.messageId + '-' + 0} style={{overflow: "scroll", maxHeight: "600px"}}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {headers.map((h, index) => {
                  return (
                    <TableCell 
                      id="header" 
                      key={index} 
                      style={{
                        textDecoration: "none", 
                        borderRadius: "0px", 
                        borderRight: "1px solid #FFFFFF", 
                        borderLeft: "1px solid #FFFFFF", 
                        backgroundColor: "#142e3e", 
                        color: "#28c4fc"
                        }}
                      >
                      <b>{cleanValue(h)}</b>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((d, i) => {
                const columns = d.split(csvParsingRegex);

                if (i === records.length - 1 && columns.length < headers.length) {
                  return (<React.Fragment key={i}></React.Fragment>)
                }

                return (
                  <TableRow key={i}>
                    {columns.map((col, c) => (
                      <TableCell key={c} style={{borderRight: "1px solid #FFFFFF", borderLeft: "1px solid #FFFFFF"}}>
                        {cleanValue(col)}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      textChunks.push(
        <pre key={props.message.messageId + '-' + 0} style={{overflowY: "scroll", maxHeight: "600px", whiteSpace: "pre-wrap", paddingRight: "5px", wordBreak: "break-all"}}>
          <code>
            {parsedMessage}
          </code>
        </pre>
      )
    }
  } else if (/^```[a-z]+\n/.test(props.message.message)) {
    messageType = props.message.message.match(/(?<=```)[a-z]+(?=\n)/);
    const parsedMessage = props.message.message.replace(/(```[a-z]+\n)|(```)/g, '');
    clipboardData = parsedMessage;

    textChunks = [
      <pre key={props.message.messageId + '-' + 0} style={{whiteSpace: "pre-wrap"}}>
        <code>
          {parsedMessage}
        </code>
      </pre>
    ];
  } else if (['file', 'csv', 'xlsx', 'pdf'].includes(props.message.type)) {
    textChunks = [(
      <Typography key={props.message.messageId + '-' + 0} style={{whiteSpace: "pre-wrap", wordBreak: "break-all", borderRadius: "10px", padding: "10px", backgroundColor: "#d6d6d6"}} variant="body1">{props.message.message}</Typography>
    )];
  } else {
    clipboardData = props.message.message;
    textChunks = props.message.message?.split('\n\n')?.map((text, index) => {
      return (
        <Typography key={props.message.messageId + '-' + index} style={{whiteSpace: "pre-wrap", wordBreak: "break-all"}} variant="body1" paragraph>{text}</Typography>
      )
    });
  }

  let viewButton;
  let recordCountText;

  if (messageType === 'csv') {
    viewButton = isTableView ? (
      <Tooltip title="View As Text" aria-label="View As Text">
        <IconButton 
          size='small' 
          onClick={() => setIsTableView(false)}
          style={{marginRight: "10px", color: "inherit"}}
        >
          <Subject fontSize='small'/>
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="View As Table" aria-label="View As Table">
        <IconButton 
          size='small' 
          onClick={() => setIsTableView(true)}
          style={{marginRight: "10px", color: "inherit"}}
        >
          <TableChart fontSize='small'/>
        </IconButton>
      </Tooltip>
    );
    recordCountText = (
      <Typography variant="subtitle2" style={{marginRight: "10px", display: "inline-block", verticalAlign: "middle"}}>Records: {recordCount}</Typography>
    );
  } else {
    viewButton = undefined;
    recordCountText = undefined;
  }
  

  return (
    <Grid item className="ai_messageContainer" xs={12} style={{padding: "25px 10px", borderRadius: "10px", textAlign: props.message.role === 'user' ? 'right' : 'left'}}>
      <div className="ai_actionContainer">
        <Tooltip title={`Click to copy messageId: ${props.message.messageId}`} aria-label="Copy messageId">
          <IconButton 
            size='small' 
            onClick={() => navigator.clipboard.writeText(props.message.messageId)}
            style={{marginRight: "10px", color: "inherit"}}
          >
            <Info fontSize='small'/>
          </IconButton>
        </Tooltip>
        { props.message.error ? (
            <Tooltip title={props.message.error} aria-label="Error">
              <IconButton 
                size='small' 
                style={{marginRight: "10px", color: "inherit"}}
              >
                <Warning fontSize='small'/>
              </IconButton>
            </Tooltip>
          ) : undefined 
        }
        { props.message.type === 'text' ? (
            <Tooltip title="Copy to clipboard" aria-label="Copy to clipboard">
              <IconButton 
                size='small' 
                onClick={() => navigator.clipboard.writeText(clipboardData)}
                style={{marginRight: "10px", color: "inherit"}}
              >
                <FileCopy fontSize='small'/>
              </IconButton>
            </Tooltip>
          ) : undefined 
        }
        <Tooltip title="Delete Message" aria-label="Delete Message">
          <IconButton 
            size='small' 
            onClick={() => props.deleteMessage(props.message.messageId)}
            style={{color: "inherit"}}
          >
            <Delete fontSize='small'/>
          </IconButton>
        </Tooltip>
      </div>
      <div style={{display: "inline-block", margin: "0px", minWidth: "400px", textAlign: "left", maxWidth: "100%"}}>
        { props.message.error ? (
            <Alert severity='error'>
              There was an error generating content. Please retry sending prompt.
            </Alert>
          ) : undefined
        }
        { messageType === 'text' ? (
            <div style={{padding: "10px 10px 0px"}}>
              {textChunks}
            </div>
          ) : (
            <div style={{backgroundColor: "#d6d6d6", padding: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px"}}>
              {textChunks}
            </div>
          )
        }
        { messageType === 'text' ? (
            <div style={{padding: "10px", textAlign: props.message.role === 'user' ? 'right' : 'left'}}>
              <Typography variant="subtitle2" style={{display: "inline-block", verticalAlign: "middle"}}>Tokens: {props.message.tokenCount ?? "--"}</Typography>
            </div>
          ) : (
            <Grid container style={{backgroundColor: "#6f6f6f", color: "#FFFFFF", padding: "10px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", textAlign: props.message.role === 'user' ? 'right' : 'left'}}>
              <Grid item xs={10} alignContent='center'>
                <Tooltip title="Copy to clipboard" aria-label="Copy to clipboard">
                  <IconButton 
                    size='small' 
                    onClick={() => {navigator.clipboard.writeText(clipboardData)}}
                    style={{marginRight: "10px", color: "inherit"}}
                  >
                    <FileCopy fontSize='small'/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download File" aria-label="Download File">
                  <IconButton 
                    size='small' 
                    onClick={() => props.downloadFile(clipboardData, messageType)}
                    style={{marginRight: "10px", color: "inherit"}}
                  >
                    <GetApp fontSize='small'/>
                  </IconButton>
                </Tooltip>
                {viewButton}
                { messageType === 'csv' && props.canAutoLoop ? (
                    <Tooltip title="Auto Process File" aria-label="Auto Process File">
                      <IconButton 
                        size='small' 
                        onClick={() => props.startAutoFileProcessing()}
                        style={{marginRight: "10px", color: "inherit"}}
                      >
                        <Loop fontSize='small'/>
                      </IconButton>
                    </Tooltip>
                  ) : undefined 
                }
                { messageType === 'csv' && !props.canAutoLoop && props.canContinueAutoLoop ? (
                    <Tooltip title="Continue Auto Processing" aria-label="Continue Auto Processing">
                      <IconButton 
                        size='small' 
                        onClick={() => props.startAutoFileProcessing()}
                        style={{marginRight: "10px", color: "inherit"}}
                      >
                        <Update fontSize='small'/>
                      </IconButton>
                    </Tooltip>
                  ) : undefined 
                }
                { messageType === 'csv' && props.isInAutoLoop ? (
                  <>
                    <Tooltip title="Clear Auto Processing" aria-label="Clear Auto Processing">
                      <IconButton 
                        size='small' 
                        onClick={() => props.clearAutoFileProcessing()}
                        style={{marginRight: "10px", color: "inherit"}}
                      >
                        <Restore fontSize='small'/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download Auto Loop File" aria-label="Download Auto Loop File">
                      <IconButton 
                        size='small' 
                        onClick={() => props.generateConcatenatedFile()}
                        style={{marginRight: "10px", color: "inherit"}}
                      >
                        <DynamicFeed fontSize='small'/>
                      </IconButton>
                    </Tooltip>
                  </>
                  ) : undefined 
                }
                { messageType === 'csv' && props.isAutoLoopStart ? (
                    <Typography variant="subtitle2" style={{marginRight: "10px", display: "inline-block", verticalAlign: "middle"}}>(Auto Loop Start)</Typography>
                  ) : undefined
                }
                {recordCountText}
                <Typography variant="subtitle2" style={{display: "inline-block", verticalAlign: "middle"}}>Tokens: {props.message.tokenCount}</Typography>
              </Grid>
              <Grid item xs={2} style={{textAlign: "right"}} alignContent='center'>
                <Typography variant="subtitle2" style={{display: "inline-block", verticalAlign: "middle"}}>{messageType}</Typography>
              </Grid>
            </Grid>
          )
        }
      </div>
    </Grid>  
  )
}