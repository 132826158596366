import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// MUI stuff
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

// styles
import './Header.css';
// import logo from "../../images/logo.png";

// context
import { AppContext } from '../../Context/AppContextProvide';

//actions
import { getLogoLink } from '../../Actions';
import { CompanyLogoContext } from '../../Context/CompanyLogoContextProvider';
import { Divider } from '@material-ui/core';
// import { baseUrl } from "../../Contants";

const Header = (props) => {
  const { user, handleLogout, loading } = React.useContext(AppContext);
  const { companyLogo } = React.useContext(CompanyLogoContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [username, setUsername] = React.useState('');
  const [logoLink, setLogoLink] = React.useState('https://showroompricing.com');

  const isAdmin = user.access_type_cd === 'a' ? true : false;
  const isUser =
    user.access_type_cd === 'rw' || user.access_type_cd === 'ro' ? true : false;
  const history = useHistory();
  const location = useLocation();
  const handleClose = (event) => {
    if (
      event &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = React.useRef(open);

  useEffect(() => {
    const lLink = new Promise((resolve) => {
      resolve(getLogoLink());
    });
    lLink.then((link) => {
      setLogoLink(link.data.data[0].logo_link);
    });

    if (
      prevOpen.current === true &&
      open === false &&
      anchorRef.current !== null
    ) {
      anchorRef.current.focus();
    }

    if (user.user_id) {
      const tempUsername = user.name === null ? user.user_name : user.name;
      setUsername(tempUsername);
    }

    prevOpen.current = open;
  }, [open, user]);

  const handlePageNavigation = (path) => {
    history.push(path, { from: location });
    handleClose();
  };

  const AppHeader = (user) => {
    let absoluteLogoLink = logoLink;
    if (!logoLink.toLowerCase().startsWith('http')) {
      absoluteLogoLink = '//' + absoluteLogoLink;
    }
    return user.user_id ? (
      <div className="appbar">
        <AppBar className="header" position="fixed">
          <Toolbar>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={absoluteLogoLink}
            >
              <img className="company-logo" src={companyLogo} alt="logo"></img>
            </a>
            <span className="spacer"></span>

            <Button
              ref={anchorRef}
              className="product__user-menu"
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <Avatar
                className="header__avatar"
                alt="profile picture"
                src={user.picture}
              />
              <MenuIcon fontSize="large" className="menu-icon" />
              <Typography className="header__username">{username}</Typography>
              <ExpandMoreIcon className="menu-expand-icon" />
            </Button>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper className="header__main-menu-paper">
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        className="header__main-menu"
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={() => handlePageNavigation('/home')}
                          className="header__menu-focus"
                        >
                          Home
                        </MenuItem>
                        <Divider />
                        {isAdmin && (
                          <div>
                            <MenuItem
                              onClick={() => handlePageNavigation('/admin')}
                              className="header__menu-focus"
                            >
                              Admin
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {(isAdmin && !!user.is_hidden) && (
                          <div>
                            <MenuItem
                              onClick={() => handlePageNavigation('/ai/prompt')}
                              className="header__menu-focus"
                            >
                              AI Prompt
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        <MenuItem
                          onClick={() => handlePageNavigation('/user')}
                          className="header__menu-focus"
                        >
                          User
                        </MenuItem>
                        <Divider />
                        {(isUser || isAdmin) && (
                          <div>
                            <MenuItem
                              onClick={() =>
                                handlePageNavigation('/lead-management')
                              }
                              className="header__menu-focus"
                            >
                              Lead Management
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {(isUser || isAdmin) && (
                          <div>
                            <MenuItem
                              onClick={() => handlePageNavigation('/products')}
                              className="header__menu-focus"
                            >
                              Product Search
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {(isUser || isAdmin) && (
                          <div>
                            <MenuItem
                              onClick={() => handlePageNavigation('/stats')}
                              className="header__menu-focus"
                            >
                              Product Stats
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {isAdmin && (
                          <div>
                            <MenuItem
                              onClick={() =>
                                handlePageNavigation('/importexport')
                              }
                              className="header__menu-focus"
                            >
                              Import/Export
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {(isUser || isAdmin) && (
                          <div>
                            <MenuItem
                              onClick={() =>
                                handlePageNavigation('/manage-samples')
                              }
                              className="header__menu-focus"
                            >
                              Manage Samples
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {(isUser || isAdmin) && (
                          <div>
                            <MenuItem
                              onClick={() =>
                                handlePageNavigation('/customer-home')
                              }
                              className="header__menu-focus"
                            >
                              Your Samples
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {isAdmin && (
                          <div>
                            <MenuItem
                              onClick={() => handlePageNavigation('/settings')}
                              className="header__menu-focus"
                            >
                              Settings
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {(isUser || isAdmin) && (
                          <div>
                            <MenuItem
                              onClick={() => handlePageNavigation('/about')}
                              className="header__menu-focus"
                            >
                              Help
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        <MenuItem
                          onClick={() => {
                            handleLogout();
                            handleClose();
                          }}
                          className="header__menu-focus"
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Toolbar>
        </AppBar>
      </div>
    ) : (
      <div className="appbar">
        <AppBar className="header" position="fixed">
          <Toolbar>
            <a target="_blank" rel="noopener noreferrer" href={logoLink}>
              <img className="company-logo" src={companyLogo} alt="logo"></img>
            </a>
            <span className="spacer"></span>
            <Button
              disabled={loading}
              className="login"
              onClick={() => handlePageNavigation('/signin')}
            >
              Login
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  };

  return AppHeader(user);
};

export default Header;
