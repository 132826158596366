import React, { useContext, useEffect, useState, useRef } from 'react';

// Dependencies
import { CompanyLogoContext } from '../../Context/CompanyLogoContextProvider';
import {
  uploadLogo as uploadLogoAction,
  setLogoLink,
  setManufacturerSwitchPosition,
  getManufacturerSwitchPosition,
  setRoomCalcSwitchPosition,
  getRoomCalcSwitchPosition,
  setLocationSwitchPosition,
  getLocationSwitchPosition,
  setCustomerCreateAccountsSwitchPos,
  getCustomerCreateAccountStatus,
  getProdCheckoutSwitchPosition,
  setProdCheckoutSwitchPosition,
  getProdFavSwitchPosition,
  setProdFavSwitchPosition,
  getMaterialClassCodes,
  getCodeTranslations,
  getUom,
  getQrPrintConfig,
  setNotificationsSwitchPos,
  getNotificationsSwitchPos,
  setRoomvoToggle,
  getRoomvoToggle,
  updateLocation,
  getSampleReminderConfig,
  setSampleReminderConfig,
  getCatalogCsv,
  setQconnectConfig,
  getQconnectToggle,
  getQconnectConfig,
} from '../../Actions';
import { AlertContext } from '../../Context/AlertContextProvider';
import MaterialClassSettings from './MaterialClassSetting';
import UomSettings from './UomSettings';
import QrPrintSetting from './QrPrintSettings';

// MUI
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import ToolTip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';

// Styles
import '../styles/Admin.css';
import { AdminContext } from '../../Context/AdminContextProvider';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const LogoLinkUpdate = () => {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const { locations, setUpdate: updateAdminContext } = useContext(AdminContext);

  const { logoLink, setUpdate: setLogoUpdate, companyLogo } = useContext(CompanyLogoContext);
  const [companyLink, setCompanyLink] = useState('www.yoursite.com');
  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);
  const [switchState, setSwitchState] = useState(false);
  const [roomvoSwitchState, setRoomvoSwitchState] = useState(false);
  const [roomCalcSwitchState, setRoomCalcSwitchState] = useState(true);
  const [locationSwitchState, setLocationSwitchState] = useState(true);
  const [userRegSwitchState, setUserRegSwitchState] = useState(false);
  const [notificationsSwitchState, setNotificationsSwitchState] =
    useState(false);
  const [prodCheckoutSwitchState, setProdCheckoutSwitchState] = useState(false);
  const [prodFavSwitchState, setProdFavSwitchState] = useState(false);
  const [open, setOpen] = useState(false);
  const [matClassCdArray, setMatClassCdArray] = useState([]);
  const [codeTranslationArray, setCodeTranslationArray] = useState([]);
  const [uomArray, setUomArray] = useState([]);
  const [dialogSelect, setDialogSelect] = useState('');
  const [margins, setMargins] = useState([]);
  const [isSampleReminderDialogOpen, setIsSampleReminderDialogOpen] =
    useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [tempLocationName, setTempLocationName] = useState('');
  const [tempLocationEmail, setTempLocationEmail] = useState('');
  const [tempLocationPhone, setTempLocationPhone] = useState('');
  const [notificationSectionToggle, setNotificationSectionToggle] = useState('config');
  const [checkoutPeriod, setCheckoutPeriod] = useState('');
  const [pastDueLimit, setPastDueLimit] = useState('');
  const [pastDueFrequency, setPastDueFrequency] = useState('');
  const [dailyStart, setDailyStart] = useState('');
  const [dailyEnd, setDailyEnd] = useState('');
  const [refreshData, setRefreshData] = useState(false);
  const [qconnectToggle, setQconnectToggle] = useState(false);
  const [qconnectUser, setQconnectUser] = useState("");
  const [qconnectPass, setQconnectPass] = useState("");

  useEffect(() => {
    if (selectedLocation) {
      const location = locations.find(l => l.location_id === selectedLocation);
      setTempLocationEmail(location?.location_email ?? '');
      setTempLocationPhone(location?.location_phone ?? '');
      setTempLocationName(location?.customer_facing_name ?? '');
    }
  }, [selectedLocation])

  useEffect(() => {
    getQrPrintConfig()
      .then((result) => {
        setMargins(result.data.data[0].config);
      })
      .catch((err) => {
        console.log(err);
      });

    getUom()
      .then((result) => {
        setUomArray(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getCodeTranslations().then((result) => {
      setCodeTranslationArray(result.data.data);
    });

    getMaterialClassCodes()
      .then((result) => {
        if (result.data.data.length) {
          setMatClassCdArray(result.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getProdCheckoutSwitchPosition()
      .then((result) => {
        if (Number(result.data.data[0].app_value) === 1) {
          setProdCheckoutSwitchState(true);
        } else {
          setProdCheckoutSwitchPosition(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getProdFavSwitchPosition()
      .then((result) => {
        if (Number(result.data.data[0].app_value) === 1) {
          setProdFavSwitchState(true);
        } else {
          setProdFavSwitchPosition(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getCustomerCreateAccountStatus().then((result) => {
      if (Number(result.data.data[0].app_value) === 1) {
        setUserRegSwitchState(true);
      } else {
        setUserRegSwitchState(false);
      }
    });

    getManufacturerSwitchPosition().then((result) => {
      if (parseInt(result.data.data[0].active) === 1) {
        setSwitchState(true);
      } else {
        setSwitchState(false);
      }
    });

    getRoomCalcSwitchPosition().then((result) => {
      if (parseInt(result.data.data[0].active) === 1) {
        setRoomCalcSwitchState(true);
      } else {
        setRoomCalcSwitchState(false);
      }
    });

    getLocationSwitchPosition().then((result) => {
      if (parseInt(result.data.data[0].active) === 1) {
        setLocationSwitchState(true);
      } else {
        setLocationSwitchState(false);
      }
    });

    getNotificationsSwitchPos().then((result) => {
      if (parseInt(result.data.sample_reminder) === 1) {
        setNotificationsSwitchState(true);
      } else {
        setNotificationsSwitchState(false);
      }
    });

    getRoomvoToggle()
      .then((result) => {
        const toggle = parseInt(result.data) === 1;
        setRoomvoSwitchState(toggle);
      })
      .catch((err) => {
        setRoomvoSwitchState(false);
      });
    
    getSampleReminderConfig()
      .then((result) => {
        setCheckoutPeriod(Math.floor(result.data.checkoutPeriod) ?? '');
        setPastDueLimit(Math.floor(result.data.pastDueLimit) ?? '');
        setPastDueFrequency(Math.floor(result.data.pastDueFrequency) ?? '');
        setDailyStart(Math.floor(result.data.dailyStart) ?? '');
        setDailyEnd(Math.floor(result.data.dailyEnd) ?? '');
      })
      .catch((err) => {
        setCheckoutPeriod('');
        setPastDueLimit('');
        setPastDueFrequency('');
        setDailyStart('');
        setDailyEnd('');
      });

    getQconnectToggle()
      .then((result) => {
        const toggle = parseInt(result.data) === 1;
        setQconnectToggle(toggle);

        if (toggle) {
          getQconnectConfig().then((result) => {
            const { username, password } = result.data;
            setQconnectUser(username ?? "");
            setQconnectPass(password ?? "");
          });
        }
      });

    if (logoLink.length) {
      setCompanyLink(logoLink);
    }

    if (refreshData) {
      setRefreshData(false);
    }
  }, [refreshData]);

  useEffect(() => {
    if (logoLink.length) {
        setCompanyLink(logoLink);
    }
  }, [logoLink]);


  const handleUpdateLogoLink = (e) => {
    setCompanyLink(e.target.value);
  };

  const handleLogoLinkUpdate = () => {
    setLogoLink(companyLink)
      .then(() => {
        setMessage('Successfully updated logo link');
        setMessageType('success');
        handleAlertOpen();
        window.location.reload();
      })
      .catch(() => {
        setMessage('Error updating logo link');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleFileUpload = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
    }
  };

  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const uploadLogo = () => {
    let formData = new FormData();
    formData.append('image', file);
    uploadLogoAction(formData)
      .then((result) => {
        setMessage('Logo Uploaded Successfully');
        setMessageType('success');
        handleAlertOpen();
        setLogoUpdate(true);
        window.location.reload();
      })
      .catch((err) => {
        setMessage('Failed to upload company logo');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  // const handleCancel = () => {
  //   window.location.reload()
  // };

  const handleChange = (event) => {
    setSwitchState(event.target.checked);
    if (Number(event.target.checked) === 1) {
      setManufacturerSwitchPosition('1');
    }
    if (Number(event.target.checked) === 0) {
      setManufacturerSwitchPosition('0');
    }

    setRefreshData(true);
  };

  const handleRoomvoChange = (event) => {
    setRoomvoSwitchState(event.target.checked);

    const toggle = Number(event.target.checked) === 1 ? '1' : '0';
    setRoomvoToggle(toggle);
    setRefreshData(true);
  };

  const handleRoomCalcChange = (event) => {
    setRoomCalcSwitchState(event.target.checked);
    if (Number(event.target.checked) === 1) {
      setRoomCalcSwitchPosition('1');
    }
    if (Number(event.target.checked) === 0) {
      setRoomCalcSwitchPosition('0');
    }
    setRefreshData(true);
  };

  const handleLocationChange = (event) => {
    setLocationSwitchState(event.target.checked);
    if (Number(event.target.checked) === 1) {
      setLocationSwitchPosition('1');
    }
    if (Number(event.target.checked) === 0) {
      setLocationSwitchPosition('0');
    }
    setRefreshData(true);
  };

  const handleProdCheckoutChange = (event) => {
    setProdCheckoutSwitchState(event.target.checked);
    if (Number(event.target.checked) === 1) {
      setProdCheckoutSwitchPosition('1');
    }
    if (Number(event.target.checked) === 0) {
      setProdCheckoutSwitchPosition('0');
    }
    setRefreshData(true);
  };

  const handleProdFavChange = (event) => {
    setProdFavSwitchState(event.target.checked);
    if (Number(event.target.checked) === 1) {
      setProdFavSwitchPosition('1');
    }
    if (Number(event.target.checked) === 0) {
      setProdFavSwitchPosition('0');
    }
    setRefreshData(true);
  };

  const handleUserRegChange = (event) => {
    setUserRegSwitchState(event.target.checked);
    if (Number(event.target.checked) === 1) {
      setCustomerCreateAccountsSwitchPos('1');
    }
    if (Number(event.target.checked) === 0) {
      setCustomerCreateAccountsSwitchPos('0');
    }
    setRefreshData(true);
  };

  const handleNotificationsChange = (event) => {
    setNotificationsSwitchState(event.target.checked);
    if (Number(event.target.checked) === 1) {
      setNotificationsSwitchPos('1');
    }
    if (Number(event.target.checked) === 0) {
      setNotificationsSwitchPos('0');
    }
    setRefreshData(true);
  };

  const renderImg = () => {
    return file === undefined ? (
      <img className="company-logo" src={companyLogo} alt="LOGO"></img>
    ) : (
      <img
        className="company-logo"
        src={file ? URL.createObjectURL(file) : null}
        alt={file ? file.name : null}
      />
    );
  };

  const renderUploadLogo = () => {
    return (
      <div>
        <div className="mnd__header">
          <Typography className="header-text" variant="h4">
            Company Logo
          </Typography>
        </div>
        <div style={{ width: '90%', margin: 'auto' }}>
          <input
            className="admin__bulletin-image-uploader"
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileUpload}
            accept="image/x-png"
          ></input>
          <h4 className="upload-logo-text">Image Preview:</h4>
          {renderImg()}
          <div style={{ textAlign: 'right' }}>
            <Button
              className="admin__search-logo-btn"
              variant="contained"
              onClick={handleUpload}
            >
              Browse Image
            </Button>
            <Button
              className="admin__upload-logo-btn"
              variant="contained"
              onClick={uploadLogo}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderLogoLink = () => {
    return (
      <div>
        <div className="mnd__header">
          <Typography className="header-text" variant="h4">
            Logo Link
          </Typography>
        </div>
        <div style={{ textAlign: 'right', width: '90%', margin: 'auto' }}>
          <TextField
            className="mnd__text-area"
            variant="filled"
            name="logolink"
            label="Eg. http://www.yoursite.com"
            placeholder="Enter Company Link"
            value={companyLink}
            onChange={handleUpdateLogoLink}
          />
          <Button
            className="admin__logo-link-btn"
            variant="contained"
            onClick={handleLogoLinkUpdate}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  };

  const renderViewRoomvo = () => {
    return (
      <Grid
        container
        spacing={0}
        style={{ margin: '30px auto 40px', textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Off
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip
            title={
              <Typography variant="subtitle1">
                Select to show roomvo on product page
              </Typography>
            }
          >
            <Switch
              checked={roomvoSwitchState}
              onChange={handleRoomvoChange}
              color="primary"
              size="medium"
            ></Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            On
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderViewManufacturer = () => {
    return (
      <Grid
        container
        spacing={0}
        style={{ margin: '30px auto 40px', textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Hide Manufacturer
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip
            title={
              <Typography variant="subtitle1">
                Select to show manufacturer on product page
              </Typography>
            }
          >
            <Switch
              checked={switchState}
              onChange={handleChange}
              color="primary"
              size="medium"
            ></Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Show Manufacturer
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderViewRoomCalculator = () => {
    return (
      <Grid
        container
        spacing={0}
        style={{ margin: '30px auto 40px', textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Hide Room Calculator
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip
            title={
              <Typography variant="subtitle1">
                Select to show room size calculator on product page
              </Typography>
            }
          >
            <Switch
              checked={roomCalcSwitchState}
              onChange={handleRoomCalcChange}
              color="primary"
              size="medium"
            ></Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Show Room calculator
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderViewLocation = () => {
    return (
      <Grid
        container
        spacing={0}
        style={{ margin: '30px auto 40px', textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Hide Location
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip
            title={
              <Typography variant="subtitle1">
                Select to show location to customer
              </Typography>
            }
          >
            <Switch
              checked={locationSwitchState}
              onChange={handleLocationChange}
              color="primary"
              size="medium"
            ></Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Show Location
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderProductCheckout = () => {
    return (
      <Grid
        container
        spacing={0}
        style={{ margin: '30px auto 40px', textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Prohibit Checkout
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip
            title={
              <Typography variant="subtitle1">
                Select to allow or prohibit product checkout
              </Typography>
            }
          >
            <Switch
              checked={prodCheckoutSwitchState}
              onChange={handleProdCheckoutChange}
              color="primary"
              size="medium"
            ></Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Allow Checkout
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderProductFavorite = () => {
    return (
      <Grid
        container
        spacing={0}
        style={{ margin: '30px auto 40px', textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Prohibit Favorite
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip
            title={
              <Typography variant="subtitle1">
                Select to allow or prohibit product favorites
              </Typography>
            }
          >
            <Switch
              checked={prodFavSwitchState}
              onChange={handleProdFavChange}
              color="primary"
              size="medium"
            ></Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Allow Favorite
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderAllowRegistration = () => {
    return (
      <Grid
        container
        spacing={0}
        style={{ margin: '30px auto 40px', textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Deny
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip
            title={
              <Typography variant="subtitle1">
                User Registration Status
              </Typography>
            }
          >
            <Switch
              checked={userRegSwitchState}
              onChange={handleUserRegChange}
              color="primary"
              size="medium"
            ></Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Allow
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderAllowNotifications = () => {
    return (
      <Grid
        container
        spacing={0}
        style={{ margin: '30px auto 40px', textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Prohibit Reminders
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip
            title={
              <Typography variant="subtitle1">
                Select to allow users to receive sample reminders
              </Typography>
            }
          >
            <Switch
              checked={notificationsSwitchState}
              onChange={handleNotificationsChange}
              color="primary"
              size="medium"
            ></Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{ display: 'block' }}>
            Allow Reminders
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaterialClassOpen = () => {
    setDialogSelect('materialClass');
    setOpen(true);
  };

  const handleUomOpen = () => {
    setDialogSelect('uom');
    setOpen(true);
  };

  const handleQrMarginOpen = () => {
    setDialogSelect('qr code margins');
    setOpen(true);
  };

  const submitLocationChanges = async () => {
    if (!selectedLocation) {
      setMessage('Failed to update location. No location selected');
      setMessageType('error');
      handleAlertOpen();

      return;
    }

    const location = locations?.find(l => l.location_id === selectedLocation);

    if (!location) {
      setMessage('Failed to update location. Location not found');
      setMessageType('error');
      handleAlertOpen();

      return;
    }

    updateLocation(
      location?.location_id,
      location?.location_name,
      location?.location_description,
      location?.sample_reminder,
      tempLocationName ? tempLocationName : null,
      tempLocationPhone ? tempLocationPhone : null,
      tempLocationEmail ? tempLocationEmail : null
    )
      .then((result) => {
        setMessage('Successfully updated location');
        setMessageType('success');
        handleAlertOpen();
        updateAdminContext(true);
        setRefreshData(true);
      })
      .catch((err) => {
        setMessage('Failed to update location');
        setMessageType('error');
        handleAlertOpen();
      });
  }

  const submitNotificationConfigChanges = async () => {
    if (!checkoutPeriod || !pastDueLimit || !pastDueFrequency || !dailyStart || !dailyEnd) {
      setMessage('Failed to update notification config. One of the settings is invalid');
      setMessageType('error');
      handleAlertOpen();

      return;
    }

    if (parseInt(dailyStart) >= parseInt(dailyEnd)) {
      setMessage('Failed to update notification config. the daily start needs to be less than the daily end');
      setMessageType('error');
      handleAlertOpen();

      return;
    }

    setSampleReminderConfig(checkoutPeriod, pastDueLimit, pastDueFrequency, dailyStart, dailyEnd)
      .then(result => {
        setMessage('Successfully updated the notification config!');
        setMessageType('success');
        handleAlertOpen();
        setRefreshData(true);
      })
      .catch(e => {
        setMessage('Failed to update notification config');
        setMessageType('error');
        handleAlertOpen();

        return;
      })
  }

  const getQConnectData = () => {
    getCatalogCsv().then((result) => {
      setMessage("Qfloors catalog processed");
      setMessageType("success");
      handleAlertOpen();
  }).catch((err) => {
      setMessage("Error processing Qfloors catalog");
      setMessageType("error");
      handleAlertOpen();
  });
  }

  const updateCredentials = () => {
    setQconnectConfig({ username: qconnectUser, password: qconnectPass }).then((result) => {
        setMessage("Updated QConnect credentials");
        setMessageType("success");
        handleAlertOpen();
    }).catch((err) => {
        setMessage("Error updating QConnect credentials");
        setMessageType("error");
        handleAlertOpen();
    });
  }

  const renderDialog = () => {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="lg"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <DialogTitle className="files-by-partner-header">
          {dialogSelect === 'materialClass'
            ? 'Material Class Codes'
            : dialogSelect === 'uom'
              ? 'Units of Measure'
              : 'Qr Print Margins'}
          <Button
            variant="outlined"
            style={{
              color: '#FF682C',
              marginLeft: 20,
              fontSize: 20,
              float: 'right',
              border: '1px solid #DCDCDC',
            }}
            onClick={handleClose}
          >
            X
          </Button>
        </DialogTitle>
        {dialogSelect === 'materialClass' ? (
          <MaterialClassSettings
            matClassCdArray={matClassCdArray}
            codeTranslationArray={codeTranslationArray}
          />
        ) : dialogSelect === 'uom' ? (
          <UomSettings
            uomArray={uomArray}
            codeTranslationArray={codeTranslationArray}
          />
        ) : (
          <QrPrintSetting margins={margins} />
        )}
      </Dialog>
    );
  };

  return (
    <div className="settings__container">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5}>
            {renderUploadLogo()}
            <Accordion style={{ marginTop: '40px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Company Logo Upload</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Use this to upload your company logo or an image of your
                  choice. (PNG files only)
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5}>
            {renderLogoLink()}
            <Accordion style={{ marginTop: '40px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Logo Link</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Here you can set your company logo as a link to wherever you
                  like.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="admin__grid-item">
          <Paper elevation={5}>
            <Typography
                variant="h4"
                className="header-text"
              >
                User Notification Settings
            </Typography>
            <Grid container spacing={1} style={{textAlign: "center"}}>
              <Grid item xs={12} sm={12} md={6} lg={5} style={{textAlign: "left", margin: "10px auto", padding: "0px 20px"}}>
                <Typography variant="h4" className="admin__product-settings" style={{textAlign: "center"}}>
                  Sample Reminders
                </Typography>
                {renderAllowNotifications()} 
                <div className="admin__product-settings">
                  <ToggleButtonGroup
                    value={notificationSectionToggle}
                    exclusive
                    onChange={(e, alignment) => setNotificationSectionToggle(alignment)}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="config" aria-label="left aligned">
                      Notification Config
                    </ToggleButton>
                    <ToggleButton value="locations" aria-label="right aligned">
                      Location Info
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                { notificationSectionToggle === 'locations' ? (
                    <div className="admin__product-settings" style={{marginTop: "20px"}}>
                      <FormControl style={{width: "60%", minWidth: "250px", margin: "auto", textAlign: "left"}}>
                        <InputLabel id="locations-label">Locations</InputLabel>
                        <Select
                          labelId="locations-label"
                          id="locations"
                          value={selectedLocation}
                          variant="filled"
                          onChange={(e) => setSelectedLocation(e.target.value)}
                        >
                          <MenuItem value="">Please select a location</MenuItem>
                          {locations?.map((l) => {
                            return (
                              <MenuItem key={l.location_id} value={l.location_id}>
                                {l.location_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      
                      { selectedLocation ? (
                          <div style={{width: "60%", minWidth: "250px", margin: "auto"}}>
                            <h3 style={{textAlign: "left"}}>Contact Info</h3>
                            <TextField
                              label="Name"
                              variant="filled"
                              fullWidth
                              style={{margin: "0px auto 5px", textAlign: "left"}}
                              value={tempLocationName}
                              onChange={(e) => setTempLocationName(e.target.value)}
                            />

                            <TextField
                              label="Email"
                              variant="filled"
                              fullWidth
                              style={{margin: "5px auto", textAlign: "left"}}
                              value={tempLocationEmail}
                              onChange={(e) => setTempLocationEmail(e.target.value)}
                            />

                            <TextField
                              label="Phone"
                              variant="filled"
                              fullWidth
                              style={{margin: "5px auto", textAlign: "left"}}
                              value={tempLocationPhone}
                              onChange={(e) => setTempLocationPhone(e.target.value)}
                            />
                            <Button 
                              variant='contained' 
                              className="admin__upload-logo-btn"
                              onClick={() => submitLocationChanges()}
                              style={{float: "right"}}
                            >
                              Update Location
                            </Button>
                          </div>
                        ) : undefined
                      }
                    </div>
                  ) : (
                    <div style={{width: "60%", minWidth: "250px", margin: "20px auto auto"}}>
                      <TextField
                        label="Checkout Period (Days)"
                        variant="filled"
                        fullWidth
                        type="number"
                        inputProps={{min: 1}}
                        style={{margin: "0px auto 5px", textAlign: "left"}}
                        value={checkoutPeriod}
                        onChange={(e) => setCheckoutPeriod(e.target.value)}
                      />

                      <TextField
                        label="Past Due Limit (Days)"
                        variant="filled"
                        fullWidth
                        type="number"
                        inputProps={{min: 1}}
                        style={{margin: "5px auto", textAlign: "left"}}
                        value={pastDueLimit}
                        onChange={(e) => setPastDueLimit(e.target.value)}
                      />

                      <TextField
                        label="Past Due Notification Frequency (Days)"
                        variant="filled"
                        fullWidth
                        type="number"
                        inputProps={{min: 1}}
                        style={{margin: "5px auto", textAlign: "left"}}
                        value={pastDueFrequency}
                        onChange={(e) => setPastDueFrequency(e.target.value)}
                      />

                      <TextField
                        label="Daily Time Range Start Hour (CST)"
                        variant="filled"
                        fullWidth
                        type="number"
                        inputProps={{min: 0, max: 23}}
                        style={{margin: "5px auto", textAlign: "left"}}
                        value={dailyStart}
                        onChange={(e) => setDailyStart(e.target.value)}
                      />

                      <TextField
                        label="Daily Time Range End Hour (CST)"
                        variant="filled"
                        fullWidth
                        type="number"
                        inputProps={{min: 1, max: 24}}
                        style={{margin: "5px auto", textAlign: "left"}}
                        value={dailyEnd}
                        onChange={(e) => setDailyEnd(e.target.value)}
                      />

                      <Button 
                        variant='contained' 
                        className="admin__upload-logo-btn"
                        onClick={() => submitNotificationConfigChanges()}
                        style={{float: "right"}}
                      >
                        Update Config
                      </Button>
                    </div>
                  )
                }
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} style={{textAlign: "left", margin: "10px auto", padding: "0px 20px"}}>
                <Typography variant="h4" className="admin__product-settings" style={{textAlign: "left"}}>
                  Instructions
                </Typography>
                <List style={{padding: "0px"}}>
                  <ListItem>
                    <ListItemText>Please click on Location Info and use the dropdown to ensure that the contact info for each location has been filled out. Notifications will not be sent out unless this information is present.</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Notifications can be toggled on or off on the site (this toggle), location, role, and user levels. In order to change the other levels you will need to edit them on the <a href="/admin">Admin page</a>.</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Sample reminders will send a notification through email or text based on the selection set by the customer when creating their profile or by the salesperson during guest checkout.</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Reminders will be sent out during the daily time range period the day before samples are due, the day of, and every day after until the past due limit is reached</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>The checkout period, past due limit, and the daily time range to send reminders can all be changed by clicking on Notification Config and submitting new values</ListItemText>
                  </ListItem>
                </List>
                <Button 
                  variant="contained" 
                  style={{margin: "10px 0px 0px"}}
                  onClick={() => setIsSampleReminderDialogOpen(true)}
                >
                  View Reminder Examples
                </Button>
              </Grid>
            </Grid>
            <Accordion style={{marginTop: "20px"}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">User Notification Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Use this switch to allow users to receive notifications. Currently this only sends out reminder notifications for checked out samples
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Dialog open={isSampleReminderDialogOpen} onClose={() => setIsSampleReminderDialogOpen(false)}>
              <Paper>
                <Typography 
                  className="header-text"
                  variant="h4" 
                >
                  <b>Example Reminders</b>
                </Typography>
                <div style={{padding: "20px"}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5" style={{marginBottom: "20px"}}><b>Email</b></Typography>
                      <Typography style={{marginBottom: "10px"}}>{"{{ First Name }}"},</Typography>
                      <Typography style={{marginBottom: "10px"}}>This is a friendly reminder you checked out the following flooring samples from {"{{ Location Name }}"}. These samples are due back to our store today or next business day.</Typography>
                      <List style={{padding: "0px", marginBottom: "10px"}}>
                        <ListItem style={{padding: "0px 20px"}}>
                          <ListItemText>- {"{{ Sample 1 Name }}"}</ListItemText>
                        </ListItem>
                        <ListItem style={{padding: "0px 20px"}}>
                          <ListItemText>- {"{{ Sample 2 Name }}"}</ListItemText>
                        </ListItem>
                      </List>
                      <Typography style={{marginBottom: "10px"}}>If you have any questions please contact the store at {"{{ Location Number }}"}.</Typography>
                      <Typography>Thank you,</Typography>
                      <Typography>{"{{ Location Name }}"}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" style={{marginBottom: "20px"}}><b>Text Message</b></Typography>
                      <Typography>{"{{ First Name }}"}, this is a reminder that the 3 samples you checked out from {"{{ Location Name }}"} are due back to our store today or next business day. For questions please call the store at {"{{ Location Number }}"}.</Typography>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
                      <Button 
                        variant="contained" 
                        onClick={() => setIsSampleReminderDialogOpen(false)}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Dialog>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="admin__grid-item">
          <Paper elevation={5}>
            <Typography variant="h4" className="header-text">
              Roomvo Settings
            </Typography>
            <Grid container spacing={1} style={{ textAlign: 'center' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={5}
                style={{
                  textAlign: 'left',
                  margin: '10px auto',
                  padding: '0px 20px',
                }}
              >
                <Typography
                  variant="h4"
                  className="admin__product-settings"
                  style={{ textAlign: 'center' }}
                >
                  Roomvo Visualizer
                </Typography>
                {renderViewRoomvo()}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{
                  textAlign: 'left',
                  margin: '10px auto',
                  padding: '0px 20px',
                }}
              >
                <Typography
                  variant="h4"
                  className="admin__product-settings"
                  style={{ textAlign: 'left' }}
                >
                  Roomvo Instructions
                </Typography>
                <Typography style={{ margin: '10px 0px 0px' }}>
                  <b>Already a Roomvo Customer?</b>
                </Typography>
                <List style={{ padding: '0px' }}>
                  <ListItem>
                    <ListItemText>
                      Please contact our support team and let them know you
                      already utilize Roomvo. There are extra steps that we need
                      to follow on the backend to ensure the integration works
                      correctly.
                    </ListItemText>
                  </ListItem>
                </List>
                <Typography style={{ margin: '10px 0px 0px' }}>
                  <b>New to Roomvo?</b>
                </Typography>
                <List style={{ padding: '0px' }}>
                  <ListItem>
                    <ListItemText>
                      1. If you have not signed up with roomvo already, please
                      go to the{' '}
                      <a
                        href="https://www.roomvo.com/share/ShowroomPricing"
                        target="_blank"
                      >
                        signup page
                      </a>
                      .
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      2. Make sure your Showroom Pricing URL is added to your
                      Roomvo account.
                    </ListItemText>
                  </ListItem>
                </List>
                <Typography>
                  <b>
                    Please note: Roomvo links will be on all product pages and
                    is based on the product SKU. If a SKU is not in Roomvo's
                    system then a random product will be shown instead.{' '}
                    <u>
                      This is a limitation with Roomvo and not Showroom Pricing.
                    </u>
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Accordion style={{ marginTop: '20px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Roomvo Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Here you can toggle your Roomvo integration to decide if it
                  should show on the product pages or not.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        { qconnectToggle ? (
            <Grid item xs={12} sm={12} md={12} lg={12} className="admin__grid-item">
              <Paper elevation={5}>
                <Typography
                    variant="h4"
                    className="header-text"
                  >
                    QConnect Settings
                </Typography>
                <Grid container spacing={1} style={{textAlign: "center"}}>
                  <Grid item xs={12} sm={12} md={6} lg={5} style={{textAlign: "left", margin: "10px auto", padding: "0px 20px"}}>
                    <Typography variant="h4" className="admin__product-settings" style={{textAlign: "left"}}>
                      QConnect Credentials
                    </Typography>
                    <div style={{margin: "10px 0px 0px", maxWidth: "300px"}}>
                      <TextField 
                        label={"Username"} 
                        placeholder={"Username"}
                        type={"text"}
                        variant="filled"
                        value={qconnectUser} 
                        required={true}
                        style={{width: "100%", margin: "10px auto"}}
                        onChange={(e) => setQconnectUser(e.target.value)}
                      />
                      <TextField 
                        label={"Password"} 
                        placeholder={"Password"}
                        type={"password"}
                        variant="filled"
                        value={qconnectPass} 
                        required={true}
                        style={{width: "100%", margin: "10px auto"}}
                        onChange={(e) => setQconnectPass(e.target.value)}
                      />
                    </div>
                    <Button variant="contained" onClick={() => updateCredentials()} style={{display: "inline-block", margin: "30px 20px 20px 0px"}}>
                      Update Credentials
                    </Button>
                    <Button variant="contained" onClick={() => getQConnectData()} style={{display: "inline-block", backgroundColor: "#4caf50", color: "#FFFFFF", margin: "30px 20px 20px 0px"}}>
                      Process QConnect Catalog
                    </Button>
                    <Typography style={{margin: "0px 0px 40px"}}>
                      *Please wait for a notification after pressing the Process Catalog button
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} style={{textAlign: "left", margin: "10px auto", padding: "0px 20px"}}>
                    <Typography variant="h4" className="admin__product-settings" style={{textAlign: "left"}}>
                      QConnect Instructions
                    </Typography>
                    <Typography style={{margin: "10px 0px"}}>
                      Please ensure the following steps are completed during the initial setup of QConnect.
                    </Typography>
                    <List>
                      <ListItem >
                        <ListItemText>1. Go to the QConnect settings and enter in your QConnect username and password.</ListItemText>
                      </ListItem>
                      <ListItem >
                        <ListItemText>2. Click on the "Send Showroom Pricing CSV" checkbox.</ListItemText>
                      </ListItem>
                      <ListItem >
                        <ListItemText>3. Tag the products in the QFloors product catalog you would like to transfer to Showroom Pricing. <b>Please note: The limit is 25,000 and it is best to only transfer non-B2B vendors through QConnect.</b></ListItemText>
                      </ListItem>
                      <ListItem >
                        <ListItemText>4. Each product has to have an SKU number in QFloors.</ListItemText>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography variant="h5">QConnect Settings</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Here you can change your QConnect credentials and automatically import your catalog from Qfloors. QConnect Imports will be shown in the Manual Files section of the home page.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>
          ) : undefined
        }

        <Grid item xs={12} sm={12} md={12} lg={12} className="admin__grid-item">
          <Paper elevation={5}>
            <Typography variant="h4" className="header-text">
              Product Scan Page Settings
            </Typography>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Manufacturer
              </Typography>
              {renderViewManufacturer()}
            </div>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Room Calculator
              </Typography>
              {renderViewRoomCalculator()}
            </div>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Location
              </Typography>
              {renderViewLocation()}
            </div>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Checkout
              </Typography>
              {renderProductCheckout()}
            </div>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Favorite
              </Typography>
              {renderProductFavorite()}
            </div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Product Page Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense={true}>
                  <ListItem>
                    <ListItemText
                      primary="Manufacturer"
                      secondary="Selector to show manufacturer on product page."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Room Calculator"
                      secondary="Selector to show room size calculator on product page."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Location"
                      secondary="Selector to show location on product page."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Checkout"
                      secondary="Selector to show product checkout on product page."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Favorite"
                      secondary="Selector to show product favorite on product page."
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5}>
            <Typography variant="h4" className="header-text">
              New User Registration
            </Typography>

            <Typography variant="h4" className="admin__product-settings">
              User Registration
            </Typography>
            {renderAllowRegistration()}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">New User Registration</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Use this switch to allow customers to create an account.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5} style={{ textAlign: 'center' }}>
            <Typography variant="h4" className="header-text">
              Import Settings
            </Typography>
            <Typography variant="h4" className="admin__product-settings">
              Material Class/UOM
            </Typography>
            <Button
              variant="contained"
              onClick={handleMaterialClassOpen}
              style={{ display: 'inline-block', margin: '30px 20px 0px' }}
            >
              View/Edit Material Classes
            </Button>
            <Button
              variant="contained"
              onClick={handleUomOpen}
              style={{ display: 'inline-block', margin: '30px 20px 0px' }}
            >
              View/Edit UOM
            </Button>
            <Accordion style={{ marginTop: '40px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Import Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Here you can add new material classes, material class codes,
                  and UOM types.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5} style={{ textAlign: 'center' }}>
            <Typography variant="h4" className="header-text">
              QR Print Settings
            </Typography>
            <Typography variant="h4" className="admin__product-settings">
              QR Code Print Margins
            </Typography>
            <Button
              variant="contained"
              onClick={handleQrMarginOpen}
              style={{ display: 'inline-block', margin: '30px 20px 40px' }}
            >
              Edit Print Margins
            </Button>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">QR Print Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Here you can adjust the print margins of the QR codes.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
      {renderDialog()}
    </div>
  );
};

export default LogoLinkUpdate;
